import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./App.css";
import WeLoading from "./views/base/Loading/WeLoading";
import Testing from "./views/base/List Devices";
const loading = () => <div>Loading...</div>;
const Login = React.lazy(() => import("./views/pages/Login"));
const TestDate = React.lazy(() => import("./testexpention"));
const Home = React.lazy(() => import("./containers/DefaultLayout"));

function App() {
  return (
    <BrowserRouter>
      <React.Suspense fallback={WeLoading()}>
        <Switch>
          <Route exact path="/login" render={(props) => <Login {...props} />} />
          <Route
            exact
            path="/test-date"
            render={(props) => <TestDate {...props} />}
          />
          <Route
            exact
            path="/test"
            render={(props) => <Testing {...props} />}
          />
          <Route path="/" render={(props) => <Home {...props} />} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
}

export default App;

// react-scrpt ^3.4.1
